import {action, observable, toJS} from "mobx";
import CryptoJS from 'crypto-js';
import {CRYPTO_KEY} from "../utils/contants";

export class LocalStore {

  @observable
  datas = {};

  constructor() {
    this.resetDatas();
  }

  get storeName() {
    return "default_data"
  }

  @action
  setItem = (key, value) => {
    this.datas = {...this.datas, [key]: value};
    this.write();
  }

  @action
  removeItem = (key) => {
    const value = toJS(this.datas);
    value[key] && delete value[key];
    this.datas = {...value};
    this.write();
  }

  getItem = (key) => {
    return toJS(this.datas[key]);
  }

  write = () => {
    let value = JSON.stringify(toJS(this.datas));
    if (process.env.NODE_ENV === 'production') {
      value = CryptoJS.AES.encrypt(value, atob(CRYPTO_KEY)).toString();
    }
    localStorage.setItem(this.storeName, value);
  }

  @action
  resetDatas = () => {
    let value = localStorage.getItem(this.storeName);
    if (value) {
      try {
        if (process.env.NODE_ENV === 'production') {
          value = CryptoJS.AES.decrypt(value, atob(CRYPTO_KEY)).toString(CryptoJS.enc.Utf8);
        }
        value = JSON.parse(value);
      } catch (e) {
        value = {}
      }
    }
    this.datas = value || {};
  }

}

export default new LocalStore();
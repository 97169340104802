import {action, computed, observable, toJS} from "mobx";
import {loading} from "@chaosoft/mobx-loading";
import request from "../utils/request";
import { JrpApi } from "../api";


export class PredictStore {
  @observable aiIndexs =  [];

  @observable predict = [];

  @observable statistics = {};

  @observable indexsObj = {};

  @loading('aiPredict/indexs')
  @action
  loadAiIndex = async () => {
    const res = await JrpApi.getAiIndexs();
    const {body} = res;
    this.aiIndexs = body;
    body.forEach((index)  => {
      this.indexsObj[index.bloombergTicker] = index.idxName;
    })
    return body;
  }

  @action 
  loadAiPredict = async(params) => {
    this.predict = [];
    const res = await JrpApi.getAiPredict(params);
    const {body} = res;
    const arr = Object.values(body);
    const data = [];
    arr.forEach((indexs) => {
      indexs.forEach((item) => {
        data.push(item)
      })
    });
    this.predict = data;
  }


  @action
  loadStatistics = async(params) => {
    const res = await JrpApi.getAiStatistics(params);
    const {body} = res;
    let datas = [];
    let columns  = [{title: '指数', dataIndex: 'name', key: 'name', render: (text) => <h4 >{text}</h4>}];
    const group = body;
        group.forEach((item, index) => {
          const row = {name:  this.indexsObj[item.name],  key: `${item.name}_${index}`};
          item.datas.forEach(({date, value, count}, i) => {
            if (index === 0) {
              columns.push({
                title:  date,
                children: [{
                  title:  '准确率',
                  dataIndex: `date${i+1}value`,
                  key: `date${i+1}value`,
                  align:  'center',
                  render: (text) => <div>{`${text}%`}</div>
                },{
                  title:  '统计值',
                  dataIndex: `date${i+1}count`,
                  key: `date${i+1}count`,
                  align:  'center',
                }]
              })
            }
            row[`date${i+1}value`] = value;
            row[`date${i+1}count`] = count;
          })
          datas.push(row);
        })
    this.statistics = {columns, datas};
  }

}

export default new PredictStore();

/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import {extend, ResponseError} from 'umi-request';
import {notification} from 'antd';
import {MD5} from 'crypto-js';
import {v4 as uuid} from 'uuid';
import upload from 'rc-upload/lib/request';
import {API_PATH, TIME_PATH} from './contants';

const SIGN_KEY = process.env.REACT_APP_SIGN_KEY;

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '请求参数错误。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

export class ApiError extends Error {
  constructor(status, reason, message) {
    super(reason);
    this.status = status;
    this.text = message;
  }
}

/**
 * 异常处理程序
 */
const errorHandler = async error => {
  if (error instanceof ResponseError) {
    const {response = {}} = error;
    const {status, url} = response;
    const body = await response.json();
    const errortext = codeMessage[status] || body.message;
    notification.error({
      message: `请求错误 ${status}: ${url}`,
      description: errortext,
    });
  }
  throw error;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  prefix: API_PATH,
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie,
});

const formDataRequest = async (ctx) => {
  const {options: {file, method, headers, onProgress, errorHandler}, url} = ctx.req;
  return new Promise((resolve, reject) => {
    const options = {
      filename: 'file',
      file: file,
      method,
      action: url,
      headers,
      onProgress,
      onSuccess: (body) => {
        resolve(body);
      },
      onError: async (error, body) => {
        try {
          await errorHandler(error);
        } catch (e) {
          e.body = body;
          reject(e);
        }
      }
    }
    upload(options);
  });
}

const authHandler = async (ctx, next) => {
  ctx.req.options.headers = {
    ...ctx.req.options.headers,
    ...createAuthHeader()
  }
  const {requestType} = ctx.req.options;
  if (requestType === 'form-data') {
    ctx.res = await formDataRequest(ctx);
  } else {
    if (requestType === 'text') {
      ctx.req.options.headers = {
        ...ctx.req.options.headers,
        "Content-Type": "text/plain;charset=utf-8"
      }
    }
    await next();
  }
  const {res} = ctx;
  if (!res.success) {
    switch (res.status) {
      case 'FOCRE_PWD':
        location.href = `${process.env.PUBLIC_URL}/init-pwd`;
        return;
      case 'CERTIFICATE':
        return;
      case 'SIGNATURE':
        await serverTime.sync();
        await authHandler(ctx, next);
        return;
    }
    throw new ApiError(res.status, res.meta.reason, res.meta.message);
  }
  ctx.res = res.body;
}

request.use(authHandler)

export function createAuthHeader() {
  const time = serverTime.current;
  const nonce = uuid();
  const token = null;
  const value = [token, time, SIGN_KEY, nonce].filter(item => !!item).sort().join('#');
  return {
    "X-NONCE": nonce,
    "X-CLIENT": 'web',
    "X-DATE": time,
    "X-AUTH-SIGN": MD5(value).toString(),
  };
}

const serverTime = (function () {
  let value;
  let startInterval = false
  const sync = async () => {
    const time = await fetch(TIME_PATH, {noauth: true});
    value = parseInt(parseFloat(time) * 1000);
    if (!startInterval) {
      //每10分钟同步一次时间
      startInterval = true;
      setInterval(sync, 1000 * 60 * 10);
      setInterval(() => {
        value += 1000;
      }, 1000)
    }
  }
  return {
    get current() {
      return `${value || new Date().getTime()}`;
    },
    sync
  }
})();

export default request;

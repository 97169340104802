import {extend} from 'umi-request';
import qs from 'query-string'
import {JDC_PATH} from "../utils/contants";


const request = extend({
  prefix: JDC_PATH,
});

export const loadDatas = async (query) => {
  const res = await request.get(qs.stringifyUrl({
    url: `/datas/index-value`,
    query,
  }, {skipNull: true, skipEmptyString: true}))
  if (res.success) {
    return res.body;
  } else {
    throw new ApiError(res.status, res.meta?.reason || res.reason, res.meta?.message || res.message)
  }
}
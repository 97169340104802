import request from "../utils/request";
import qs from 'query-string';

export const findSessions = async (query) => {
  return request.get(qs.stringifyUrl({
    url: '/chat/sessions', query
  }, {skipNull: true, skipEmptyString: true}))
}

export const getMessage = async (sessionkey) => {
  return request.get(`/chat/sessions/${sessionkey}/messages`)
}

export const postMessage = async (question, prevKey) => {
  return request.post(qs.stringifyUrl({
    url: '/chat/messages', query: {prevKey}
  }, {skipNull: true, skipEmptyString: true}), {data: question, requestType: 'text'})
}
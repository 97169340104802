import request from "../utils/request";
import qs from 'query-string';

export const findTasks = async (query) => {
  return request.get(qs.stringifyUrl({
    url: '/tasks', query
  }, {skipNull: true, skipEmptyString: true}))
}

export const getTask = async (key) => {
  return request.get(`/tasks/${key}`)
}
import {extend} from 'umi-request';
import {JRP_PATH} from "../utils/contants";


const request = extend({
  prefix: JRP_PATH,
});

// ai可选指数列表
export const getAiIndexs = async () => {
  return request.get(`/ai/indexs`)
}

// 预测列表
export const getAiPredict = async (params) => {
  return request.get(`/ai/predict`, {params})
}

// 预测统计
export const getAiStatistics = async (params) => {
  return request.get(`/ai/statistics`, {params})
}
import React from "react";
import Loadable from 'react-loadable';
import {PageLoading} from "@ant-design/pro-layout";
import * as stores from "./stores";
import {Provider} from "mobx-react";
import {BrowserRouter, Redirect, Route as ReactRoute, Switch} from "react-router-dom";
import routes from "./routes";
import {ConfigProvider} from "antd";
import zhCN from 'antd/lib/locale/zh_CN';

const App = () => {

  const importRouteComponent = (routes) => {
    return routes.map(item => ({
      ...item,
      component: item.component && (typeof item.component === 'string' ? Loadable({
        loader: () => import(`${item.component}`),
        loading: () => <PageLoading/>,
      }) : item.component),
      routes: item.routes && importRouteComponent(item.routes)
    }))
  }

  const createLazyRoute = (routes) => {
    return routes && (
      <Switch>
        {routes.map(({path, Route = ReactRoute, component: Component, redirect, routes: childs, ...restProps}, index) => {
          if (redirect) {
            return <Redirect key={index} {...restProps} from={path} to={redirect}/>
          }
          return (
            <Route key={index} path={path} {...restProps} routes={childs} render={(props) => {
              if (Component) {
                return (
                  <Component {...props} routes={childs}>
                    {childs && childs.length > 0 && createLazyRoute(childs)}
                  </Component>
                )
              }
              return childs && childs.length > 0 && createLazyRoute(childs);
            }}/>
          )
        })}
      </Switch>
    )
  }

  const theme = {
    components: {
      Layout: {
        siderBg: '#2B2D31',
      }
    }
  }

  return (
    <Provider {...stores}>
      <ConfigProvider locale={zhCN} theme={theme}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          {createLazyRoute(importRouteComponent(routes))}
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  )
}

export default App;

import React from "react";
import {
  CommentOutlined,
  FundOutlined,
  GlobalOutlined,
  HeatMapOutlined,
  HomeOutlined,
  LineChartOutlined
} from "@ant-design/icons";

export default [{
  path: '/home',
  name: '首頁',
  component: './pages/home',
  exact: true
}, {
  path: '/gpt',
  name: '理專問答系統',
  component: './pages/gpt',
  exact: true,
}, {
  path: '/',
  component: './layout/basic-layout',
  routes: [{
    path: '/',
    redirect: '/home',
    exact: true
  }, {
    path: '/home',
    name: '首頁',
    icon: <HomeOutlined/>,
    exact: true
  }, {
    path: '/gpt',
    name: '理專問答系統',
    icon: <FundOutlined/>,
    exact: true,
  }, {
    path: '/news',
    name: '市場新聞摘要',
    component: './pages/news',
    icon: <GlobalOutlined/>,
    exact: true
  }, {
    path: '/comment',
    name: '市場研究報告',
    component: './pages/comment',
    icon: <CommentOutlined/>,
    exact: true
  }, {
    path: '/predict',
    name: '經濟指標預測',
    component: './pages/predict',
    icon: <HeatMapOutlined/>,
    exact: true,
  }, {
    path: 'https://jrp.jfquant.com/',
    name: 'ROBO績效追蹤',
    icon: <LineChartOutlined/>,
    exact: true,
    hideInMenu: true,
  }]
}]
